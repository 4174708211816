<template>
  <div class="errorWrap">
    <ul class="timeWrap">
      <li
        v-for="(item, index) in timeList"
        :key="index"
        :class="timestatus == item.id ? 'on' : ''"
        @click="changeTime(item.id)"
      >
        {{ item.name }}
      </li>
    </ul>
    <ul class="wrongWrap">
      <li
        v-for="(item, index) in wrongList"
        :key="index"
        :class="wrongstatus == item.id ? 'on' : ''"
        @click="changeWrong(item.id)"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="noData" v-if="errorList.length == 0">
      <img src="../assets/images/noData.png" alt="" />
      <p>暂无更多</p>
    </div>
    <template v-else>
    <ul class="mainWrap">
      <li
        v-for="(item, index) in errorList"
        :key="index"
        @click="searchTo(item)"
      >
        <div class="itemWrap">
          <span class="colorBlue">[{{ item.rtypename }}]</span>
          <span v-html="item.content"></span>
          <div>
            <el-tag
              class="mr10"
              type="info"
              effect="plain"
              size="mini"
              v-for="(ite, ind) in item.knowledge"
              :key="ind"
              >{{ ite }}</el-tag
            >
          </div>
        </div>
      </li>
    </ul>
    <p class="moreBtn" @click="getMore">
      <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
    </p>
    </template>
  </div>
</template>

<script>
import { wronglist } from "../assets/api";
export default {
  data() {
    return {
      timeList: [
        {
          id: 1,
          name: "近一周",
        },
        {
          id: 2,
          name: "近一月",
        },
        {
          id: 3,
          name: "近三月",
        },
        {
          id: 0,
          name: "全部",
        },
      ],
      timestatus: 0,
      wrongList: [
        {
          id: 0,
          name: "全部错题",
        },
        {
          id: 1,
          name: "错误两次",
        },
        {
          id: 2,
          name: "错误三次及以上",
        },
      ],
      wrongstatus: 0,
      errorList: [],
      page: 1,
      btnMessage: "加载更多",
    };
  },
  mounted() {
    this.wronglist();
  },
  methods: {
    searchTo(data) {
      this.$router.push({
        path: "/pape_doRecite",
        query: {
          question_id: data.question_id,
          title: "我的错题",
          num: data.num,
          wrongid: data.wrongid,
        },
      });
    },
    wronglist() {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        page: this.page,
        timestatus: this.timestatus,
        wrongstatus: this.wrongstatus,
      };
      wronglist(params).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            if (this.errorList == 0) {
              this.errorList = res.data;
            } else {
              this.errorList = this.errorList.concat(res.data);
            }
          } else {
            this.btnMessage = "暂无更多";
          }
        }
      });
    },
    changeTime(id) {
      this.timestatus = id;
      this.page = 1;
      this.errorList = [];
      this.btnMessage = "加载更多";
      this.wronglist();
    },
    changeWrong(id) {
      this.wrongstatus = id;
      this.page = 1;
      this.errorList = [];
      this.btnMessage = "加载更多";
      this.wronglist();
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.wronglist();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.errorWrap {
  .noData {
    margin-top: 115px;
    margin-bottom: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    color: #999;
    img {
      width: 88px;
      height: 92px;
      margin-bottom: 25px;
    }
  }
  .timeWrap {
    display: flex;
    padding: 30px;
    box-sizing: border-box;
    li {
      margin-right: 40px;
      font-size: 18px;
      cursor: pointer;
      position: relative;
    }
    .on {
      font-weight: bold;
      color: var(--change-color);

      &::after {
        clear: both;
        content: "";
        position: absolute;
        left: 50%;
        bottom: -9px;
        width: 28px;
        height: 2px;
        margin-left: -14px;
        background: var(--change-color);
        border-radius: 1px;
      }
    }
  }
  .wrongWrap {
    display: flex;
    padding-left: 30px;
    box-sizing: border-box;
    font-size: 14px;
    li {
      margin-right: 15px;
      padding: 8px 15px;
      box-sizing: border-box;
      color: #666;
      border-radius: 18px;
      background: #f6f6f6;
      cursor: pointer;
    }
    .on {
      background:#fff;border:1px solid var(--change-color);
      color: var(--change-color);
    }
  }
  .mainWrap {
    padding-top: 5px;
    box-sizing: border-box;
    li {
      padding: 0 30px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 30px;
      color: #333;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      }
      .itemWrap {
        padding: 24px 0;
        border-bottom: 1px dashed #e1e1e1;
        box-sizing: border-box;
        .colorBlue {
          color: var(--change-color);
        }
      }
    }
    // li:last-child {
    //   .itemWrap {
    //     border: none;
    //   }
    // }
  }
  .moreBtn {
    margin: 30px auto;
    width: 100px;
    height: 30px;
    font-size: 14px;
    border-radius: 18px;
    border: 1px solid #e1e1e1;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #666;
    &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
    i {
      font-size: 14px;
    }
  }
}
</style>
